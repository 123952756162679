import { APIRoutes } from 'constants/';
import {
  Profiler,
  SurveyHistoryItem,
  CommitmentOption,
  Commitment,
  CommitmentActionTypes,
} from 'types/';
import {
  SupplierLogInPayload,
  SupplierStatsPayload,
  SupplierSignUpPayload,
  CommitmentResultPayload,
  ProfilerQuestionPayload,
  CommitmentAnswerPayload,
  SupplierStatsQueryParams,
  CommitmentInitiatePayload,
  CommitmentNextActionPayload,
} from 'store/api/types/';
import { baseApi, OnboardingTags, SurveyTags, UserTags } from './baseApi';

export const surveysApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    surveyHistory: build.query<SurveyHistoryItem[], void>({
      query: () => ({
        url: APIRoutes.SurveysHistory,
        method: 'GET',
      }),
    }),
    commitmentOptions: build.query<CommitmentOption[], void>({
      query: () => ({
        url: APIRoutes.CommitmentOptions,
        method: 'GET',
      }),
      providesTags: [SurveyTags.Options],
      keepUnusedDataFor: 5,
    }),
    commitmentNextAction: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentNextActionPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentNext,
        method: 'POST',
        body,
      }),
      invalidatesTags: [UserTags.UserBasic, UserTags.UserFull],
    }),
    commitmentInitiate: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentInitiatePayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentInitiate,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState, SurveyTags.Options],
    }),
    commitmentSpecialInitiate: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentInitiatePayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentInitiateSpecial,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState],
    }),
    commitmentDummyData: build.query<void, void>({
      query: () => ({
        url: APIRoutes.TEMP_CommitmentDummyData,
        method: 'GET',
      }),
    }),
    commitmentResult: build.mutation<void, CommitmentResultPayload>({
      query: (body) => ({
        url: APIRoutes.CommitmentResult,
        method: 'POST',
        body,
      }),
    }),
    commitmentAnswer: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentAnswerPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentSubmitAnswer,
        method: 'POST',
        body,
      }),
    }),
    commitmentAnswerAndNextAction: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentResultPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentSubmitAnswerAndNextAction,
        method: 'POST',
        body,
      }),
    }),
    getProfilerQuestion: build.query<Profiler, ProfilerQuestionPayload | void>({
      query: (body) => ({
        url: `${APIRoutes.TEMP_Profiler_Data}${
          body?.id ? `?id=${body.id}` : ''
        }`,
        method: 'GET',
      }),
    }),
    supplierLogIn: build.mutation<
      Commitment<CommitmentActionTypes>,
      SupplierLogInPayload
    >({
      query: (body) => ({
        url: APIRoutes.SupplierLogIn,
        method: 'POST',
        body,
      }),
    }),
    supplierSignUp: build.mutation<
      Commitment<CommitmentActionTypes>,
      SupplierSignUpPayload
    >({
      query: (body) => ({
        url: APIRoutes.SupplierSignUp,
        method: 'POST',
        body,
      }),
    }),
    supplierStats: build.query<
      SupplierStatsPayload[],
      SupplierStatsQueryParams | void
    >({
      query: (body) => ({
        url: `${APIRoutes.SupplierStats}${
          body
            ? `?${(Object.keys(body) as (keyof SupplierStatsQueryParams)[])
                .map((key) => `${key}=${body[key]}`)
                .join('&')}`
            : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSurveyHistoryQuery,
  useCommitmentDummyDataQuery,
  useCommitmentInitiateMutation,
  useCommitmentSpecialInitiateMutation,
  useCommitmentNextActionMutation,
  useCommitmentOptionsQuery,
  useCommitmentResultMutation,
  useCommitmentAnswerMutation,
  useCommitmentAnswerAndNextActionMutation,
  useSupplierLogInMutation,
  useSupplierSignUpMutation,
  useSupplierStatsQuery,
  useLazyCommitmentDummyDataQuery,
  useLazyGetProfilerQuestionQuery,
} = surveysApi;
